// Square.tsx
import React, { useState } from 'react';
import './Square.css';

interface SquareProps {
  color: string;
  content?: string;
  onDelete?: () => void;
  onUpdate?: (content: string) => void;
}

export const Square: React.FC<SquareProps> = ({ 
  color, 
  content = "Click to edit", 
  onDelete,
  onUpdate 
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(content);
  const [isHovered, setIsHovered] = useState(false);

  const handleBlur = () => {
    setIsEditing(false);
    if (onUpdate && text !== content) {
      onUpdate(text);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleBlur();
    }
  };

  return (
    <div 
      className="square" 
      style={{ '--glow-color': color } as React.CSSProperties}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && onDelete && (
        <div 
          className="delete-button"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          ×
        </div>
      )}
      <div className="square-content" onClick={() => setIsEditing(true)}>
        {isEditing ? (
          <input
            value={text}
            onChange={(e) => setText(e.target.value)}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
            autoFocus
          />
        ) : (
          <span>{text}</span>
        )}
      </div>
    </div>
  );
};