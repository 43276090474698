export const colors = {
    // Your original colors
    cinder: '#191622',      // Dark base/background
    lavender: '#bc7fd7',    // Soft purple
    purpleHeart: '#6928b2', // Bold purple
    hippieBlue: '#599ab4',  // Muted teal
  
    // Additional 80's inspired colors
    hotPink: '#ff69b4',     // Classic 80's pink
    neonYellow: '#fff700',  // Bright accent
    electricBlue: '#00ffff', // Cyan/bright blue
    sunsetOrange: '#ff6b35', // Warm accent
    retroTeal: '#40e0d0',    // Miami vice inspired
    neonPurple: '#9d00ff',   // Bright purple
    mintGreen: '#98ff98',    // Soft pastel
    darkestPurple: '#2d1b36', // Deep background option
    
    // Gradient suggestions
    gradients: {
      retroSunset: 'linear-gradient(45deg, #ff6b35 0%, #6928b2 100%)',
      neonGlow: 'linear-gradient(45deg, #00ffff 0%, #ff69b4 100%)',
      purpleHaze: 'linear-gradient(45deg, #191622 0%, #9d00ff 100%)',
    }
  };
  
  // Usage example:
  // import { colors } from './colors';
  // background-color: ${colors.hotPink};
  // background: ${colors.gradients.retroSunset};