// Objectives.tsx
import { useState, useEffect } from 'react';
import { Square } from './Square';
import { AddButton } from './AddButton';
import { colors } from './Colors';
import { db, auth } from './firebase';
import { 
  collection, 
  addDoc, 
  deleteDoc, 
  updateDoc, 
  doc, 
  query, 
  where, 
  onSnapshot 
} from 'firebase/firestore';

interface Objective {
  id: string;
  content: string;
  userId: string;
  createdAt: number;
}

interface ObjectivesProps {
  onDeleteRequest: (id: string, type: 'Objective', onConfirm: () => void) => void;
}

export const Objectives: React.FC<ObjectivesProps> = ({ onDeleteRequest }) => {
  const [objectives, setObjectives] = useState<Objective[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const objectivesQuery = query(
          collection(db, 'objectives'),
          where('userId', '==', user.uid)
        );

        const objectivesUnsubscribe = onSnapshot(objectivesQuery, async (snapshot) => {
          let objectivesData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          } as Objective));
          
          // If no objectives exist, create initial objective in Firestore
          if (objectivesData.length === 0) {
            try {
              const newObjectiveRef = await addDoc(collection(db, 'objectives'), {
                content: "New Objective",
                userId: user.uid,
                createdAt: Date.now()
              });
              
              objectivesData = [{
                id: newObjectiveRef.id,
                content: "New Objective",
                userId: user.uid,
                createdAt: Date.now()
              }];
            } catch (error) {
              console.error('Error creating initial objective:', error);
            }
          }
          
          setObjectives(objectivesData.sort((a, b) => b.createdAt - a.createdAt));
          setLoading(false);
        });

        return () => objectivesUnsubscribe();
      } else {
        setObjectives([]);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const addObjective = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      await addDoc(collection(db, 'objectives'), {
        content: "New Objective",
        userId: user.uid,
        createdAt: Date.now()
      });
    } catch (error) {
      console.error('Error adding objective:', error);
    }
  };

  const updateObjective = async (id: string, content: string) => {
    try {
      const objectiveRef = doc(db, 'objectives', id);
      await updateDoc(objectiveRef, {
        content: content
      });
    } catch (error) {
      console.error('Error updating objective:', error);
    }
  };

  const handleDelete = (id: string) => {
    onDeleteRequest(id, 'Objective', async () => {
      try {
        const objectiveRef = doc(db, 'objectives', id);
        await deleteDoc(objectiveRef);
      } catch (error) {
        console.error('Error deleting objective:', error);
      }
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="objectives-container">
      <div className="squares-container">
        {objectives.map((objective) => (
          <Square
            key={objective.id}
            color={colors.electricBlue}
            content={objective.content}
            onDelete={() => handleDelete(objective.id)}
            onUpdate={(content) => updateObjective(objective.id, content)}
          />
        ))}
      </div>
      <AddButton onClick={addObjective} color={colors.electricBlue} />
    </div>
  );
};