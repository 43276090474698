import { useState, useEffect } from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from './firebase';  // Import the auth instance

interface AuthProps {
  onAuthComplete: () => void;
}

export const Auth = ({ onAuthComplete }: AuthProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const provider = new GoogleAuthProvider();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticated(true);
        onAuthComplete();
      }
    });
    return () => unsubscribe();
  }, [onAuthComplete]);

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
      setIsAuthenticated(true);
      onAuthComplete();
    } catch (error) {
      console.error('Error signing in:', error);
    }
  };

  if (isAuthenticated) {
    return null;
  }

  return (
    <button 
      onClick={handleGoogleSignIn}
      style={{
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#4285f4',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer'
      }}
    >
      Sign in with Google
    </button>
  );
};