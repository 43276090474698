// App.tsx
import { useState } from 'react';
import './App.css';
import { Auth } from './Auth';
import { Goals } from './Goals';
import { Objectives } from './Objectives';
import { Tasks } from './Tasks';
import { Modal } from './DeleteModal';
import { colors } from './Colors';
import './goalsobjectivestasks.css';

interface DeleteModalState {
  show: boolean;
  id: string | null;
  type: 'Goal' | 'Objective' | 'Task' | null;
  onConfirm: (() => void) | null;
}

function App() {
  const [isAuthed, setIsAuthed] = useState(false);
  const [deleteModal, setDeleteModal] = useState<DeleteModalState>({
    show: false,
    id: null,
    type: null,
    onConfirm: null
  });

  const showDeleteModal = (
    id: string, 
    type: 'Goal' | 'Objective' | 'Task',
    onConfirm: () => void
  ) => {
    setDeleteModal({
      show: true,
      id,
      type,
      onConfirm
    });
  };

  const closeDeleteModal = () => {
    setDeleteModal({
      show: false,
      id: null,
      type: null,
      onConfirm: null
    });
  };

  const handleConfirmDelete = () => {
    if (deleteModal.onConfirm) {
      deleteModal.onConfirm();
    }
    closeDeleteModal();
  };

  const getModalColor = () => {
    switch (deleteModal.type) {
      case 'Goal':
        return colors.lavender;
      case 'Objective':
        return colors.electricBlue;
      case 'Task':
        return colors.hotPink;
      default:
        return colors.lavender;
    }
  };

  return (
    <div className="App">
      {!isAuthed ? (
        <header className="App-header">
          <Auth onAuthComplete={() => setIsAuthed(true)} />
        </header>
      ) : (
        <div className="dashboard-container">
          <div className="goals-section">
            <Goals onDeleteRequest={showDeleteModal} />
          </div>
          <div className="lower-section">
            <div className="objectives-section">
              <Objectives onDeleteRequest={showDeleteModal} />
            </div>
            <div className="tasks-section">
              <Tasks onDeleteRequest={showDeleteModal} />
            </div>
          </div>
          <Modal
            isOpen={deleteModal.show}
            onClose={closeDeleteModal}
            onConfirm={handleConfirmDelete}
            color={getModalColor()}
            itemType={deleteModal.type || ''}
          />
        </div>
      )}
    </div>
  );
}

export default App;