// Goals.tsx
import { useState, useEffect } from 'react';
import { Square } from './Square';
import { AddButton } from './AddButton';
import { colors } from './Colors';
import { db, auth } from './firebase';
import { 
  collection, 
  addDoc, 
  deleteDoc, 
  updateDoc, 
  doc, 
  query, 
  where, 
  onSnapshot 
} from 'firebase/firestore';

interface Goal {
  id: string;
  content: string;
  userId: string;
  createdAt: number;
}

interface GoalsProps {
  onDeleteRequest: (id: string, type: 'Goal', onConfirm: () => void) => void;
}

export const Goals: React.FC<GoalsProps> = ({ onDeleteRequest }) => {
  const [goals, setGoals] = useState<Goal[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const goalsQuery = query(
          collection(db, 'goals'),
          where('userId', '==', user.uid)
        );

        const goalsUnsubscribe = onSnapshot(goalsQuery, async (snapshot) => {
          let goalsData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          } as Goal));
          
          // If no goals exist, create initial goal in Firestore
          if (goalsData.length === 0) {
            try {
              const newGoalRef = await addDoc(collection(db, 'goals'), {
                content: "New Goal",
                userId: user.uid,
                createdAt: Date.now()
              });
              
              goalsData = [{
                id: newGoalRef.id,
                content: "New Goal",
                userId: user.uid,
                createdAt: Date.now()
              }];
            } catch (error) {
              console.error('Error creating initial goal:', error);
            }
          }
          
          setGoals(goalsData.sort((a, b) => b.createdAt - a.createdAt));
          setLoading(false);
        });

        return () => goalsUnsubscribe();
      } else {
        setGoals([]);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);
  
  const addGoal = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      await addDoc(collection(db, 'goals'), {
        content: "New Goal",
        userId: user.uid,
        createdAt: Date.now()
      });
    } catch (error) {
      console.error('Error adding goal:', error);
    }
  };

  const updateGoal = async (id: string, content: string) => {
    try {
      const goalRef = doc(db, 'goals', id);
      await updateDoc(goalRef, {
        content: content
      });
    } catch (error) {
      console.error('Error updating goal:', error);
    }
  };

  const handleDelete = (id: string) => {
    onDeleteRequest(id, 'Goal', async () => {
      try {
        const goalRef = doc(db, 'goals', id);
        await deleteDoc(goalRef);
      } catch (error) {
        console.error('Error deleting goal:', error);
      }
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="goals-container">
      <div className="squares-container">
        {goals.map((goal) => (
          <Square
            key={goal.id}
            color={colors.lavender}
            content={goal.content}
            onDelete={() => handleDelete(goal.id)}
            onUpdate={(content) => updateGoal(goal.id, content)}
          />
        ))}
      </div>
      <AddButton onClick={addGoal} color={colors.lavender} />
    </div>
  );
};