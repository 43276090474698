// Tasks.tsx
import { useState, useEffect } from 'react';
import { Square } from './Square';
import { AddButton } from './AddButton';
import { colors } from './Colors';
import { db, auth } from './firebase';
import { 
  collection, 
  addDoc, 
  deleteDoc, 
  updateDoc, 
  doc, 
  query, 
  where, 
  onSnapshot 
} from 'firebase/firestore';

interface Task {
  id: string;
  content: string;
  userId: string;
  createdAt: number;
}

interface TasksProps {
  onDeleteRequest: (id: string, type: 'Task', onConfirm: () => void) => void;
}

export const Tasks: React.FC<TasksProps> = ({ onDeleteRequest }) => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const tasksQuery = query(
          collection(db, 'tasks'),
          where('userId', '==', user.uid)
        );

        const tasksUnsubscribe = onSnapshot(tasksQuery, async (snapshot) => {
          let tasksData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          } as Task));
          
          // If no tasks exist, create initial task in Firestore
          if (tasksData.length === 0) {
            try {
              const newTaskRef = await addDoc(collection(db, 'tasks'), {
                content: "New Task",
                userId: user.uid,
                createdAt: Date.now()
              });
              
              tasksData = [{
                id: newTaskRef.id,
                content: "New Task",
                userId: user.uid,
                createdAt: Date.now()
              }];
            } catch (error) {
              console.error('Error creating initial task:', error);
            }
          }
          
          setTasks(tasksData.sort((a, b) => b.createdAt - a.createdAt));
          setLoading(false);
        });

        return () => tasksUnsubscribe();
      } else {
        setTasks([]);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const addTask = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      await addDoc(collection(db, 'tasks'), {
        content: "New Task",
        userId: user.uid,
        createdAt: Date.now()
      });
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

  const updateTask = async (id: string, content: string) => {
    try {
      const taskRef = doc(db, 'tasks', id);
      await updateDoc(taskRef, {
        content: content
      });
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const handleDelete = (id: string) => {
    onDeleteRequest(id, 'Task', async () => {
      try {
        const taskRef = doc(db, 'tasks', id);
        await deleteDoc(taskRef);
      } catch (error) {
        console.error('Error deleting task:', error);
      }
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="tasks-container">
      <div className="squares-container">
        {tasks.map((task) => (
          <Square
            key={task.id}
            color={colors.hotPink}
            content={task.content}
            onDelete={() => handleDelete(task.id)}
            onUpdate={(content) => updateTask(task.id, content)}
          />
        ))}
      </div>
      <AddButton onClick={addTask} color={colors.hotPink} />
    </div>
  );
};