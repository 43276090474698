import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB-Jo7NnzwdvWCG9UJSw84TojS3ajIxfcM",
  authDomain: "productmanager5.firebaseapp.com",
  projectId: "productmanager5",
  storageBucket: "productmanager5.firebasestorage.app",
  messagingSenderId: "744604781898",
  appId: "1:744604781898:web:6de8f8c11b643d638bc5b3",
  measurementId: "G-MYQP6X5RQC"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);