// AddButton.tsx
import React from 'react';
import './AddButton.css';

interface AddButtonProps {
  onClick: () => void;
  color: string;
}

export const AddButton: React.FC<AddButtonProps> = ({ onClick, color }) => {
  return (
    <div 
      className="add-button" 
      onClick={onClick}
      style={{ 
        '--button-color': color,
      } as React.CSSProperties}
    >
      <span>+</span>
    </div>
  );
};